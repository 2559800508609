module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Powercor/CitiPower","short_name":"Powercor","start_url":"/","background_color":"#1e398d","theme_color":"#1e398d","display":"minimal-ui","icon":"src/assets/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e5e199e13051ed5a72d46e70a76f2989"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkOnly"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"verbose":true,"url":"https://cms.powercor.com.au/graphql","schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":1,"timeout":300000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"debug":{"preview":true,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-accessibilityjs/gatsby-browser.js'),
      options: {"plugins":[],"injectStyles":false,"errorClassName":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-4961445-11","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5GND9Z","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
